<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          icon="menu"
          aria-label="Menu"
          @click="toggleLeftDrawer"
        />

        <q-toolbar-title style="font-size: 1.2em">
          {{ headerTitle }}
        </q-toolbar-title>
        <q-space />
        <q-btn v-if="!signedIn" round flat icon="r_shield" @click="SignIn" />
        <q-btn v-else round flat icon="r_logout" @click="SignOut" />
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" show-if-above bordered>
      <q-card flat>
        <q-card-section class="text-h5"> Budget Tracker </q-card-section>
      </q-card>
      <q-list>
        <q-item
          clickable
          v-for="(m, i) in menu"
          :key="i"
          @click="NavTo(m.route)"
        >
          <q-item-section avatar>
            <q-icon :name="m.icon" />
          </q-item-section>
          <q-item-section>{{ m.label }}</q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch, provide } from "vue";
import { useStore } from "vuex";
import general from "../mixins/general";
import auth from "../mixins/auth";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Capacitor } from "@capacitor/core";
// import "@codetrix-studio/capacitor-google-auth";
// import { Plugins } from "@capacitor/core";

export default defineComponent({
  name: "MainLayout",

  components: {},

  setup() {
    const q = useQuasar();
    const router = useRouter();
    const store = useStore();
    const menu = store.getters["Menu"];
    const leftDrawerOpen = ref(false);
    const { navigateTo } = general();
    const { SignIn, SignOut } = auth();
    const headerTitle = computed(() => store.getters["HeaderTitle"]);
    const signedIn = computed(() => store.getters["SignedIn"]);
    const isNative = ref(true);
    const NavTo = (data) => {
      router.push(data);
      //navigateTo(data)
    };

    isNative.value = Capacitor.isNativePlatform();

    provide("isNative", isNative);

    watch(
      signedIn,
      () => {
        if (!signedIn.value && !isNative.value) {
          router.push({ name: "Unauthorized" });
        }
      },
      { immediate: true, deep: true }
    );

    const listenStatus = (val) => {
      store.dispatch("UpdateSignInStatus", val);
    };

    const initClient = () => {
      window.gapi.client
        .init({
          apiKey: "AIzaSyCB4RyhZPd1GNWHBMZUSZAMltVw1PrlRVs",
          clientId:
            "638500515999-qqedfumejapak00tsg78735skq55qfv3.apps.googleusercontent.com",
          discoveryDocs: [
            "https://sheets.googleapis.com/$discovery/rest?version=v4",
          ],
          scope: "https://www.googleapis.com/auth/spreadsheets",
        })
        .then(function () {
          // Listen for sign-in state changes.
          console.log(
            "GAPI initialized",
            window.gapi,
            window.gapi.auth2.getAuthInstance(),
            window.gapi.auth2.getAuthInstance().isSignedIn.get()
          );

          setupListeners();

          // if (q.platform.is.mobile){
          //   SignOut();
          // }
        });
    };

    const setupListeners = () => {
      window.gapi.auth2.getAuthInstance().isSignedIn.listen(listenStatus);

      // // Handle the initial sign-in state.
      listenStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
    };

    const HandleClientLoad = () => {
      //version 1:
      window.gapi.load("client:auth2", initClient);
    };

    onMounted(() => {
      isNative.value = Capacitor.isNativePlatform();
      console.warn(
        "main Layout",
        GoogleAuth,
        q.platform,
        Capacitor,
        Capacitor.isNativePlatform()
      );
      //setupListeners();
      // GoogleAuth.init({
      //   client_id:
      //     "638500515999-qqedfumejapak00tsg78735skq55qfv3.apps.googleusercontent.com",
      //   scopes: ["profile", "email"],
      //   grantOfflineAccess: true,
      // });

      if (!isNative.value) {
        setTimeout(() => {
          HandleClientLoad();
        }, 500);
      }
    });

    return {
      SignOut,
      SignIn,
      signedIn,
      headerTitle,
      router,
      NavTo,
      navigateTo,
      menu,
      leftDrawerOpen,
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },
    };
  },
});
</script>
